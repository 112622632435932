smoothly-form {
	display: block;
	position: relative;
	--background-color: var(--smoothly-color-shade);
	--text-color: var(--smoothly-color-contrast)
}
smoothly-form > form {
	position: relative;
	min-width: 15em;
}
smoothly-form > form > fieldset {
	display: flex;
	flex-flow: row wrap;
	margin-inline-start: unset;
	margin-inline-end: unset;
	padding-block-start: unset;
	padding-inline-start: unset;
	padding-inline-end: unset;
	padding-block-end: unset;
	min-inline-size: unset;
	border-width: unset;
	border-style: unset;
	border-color: unset;
	border-image: unset;
}
smoothly-form > form > fieldset > * {
	flex-grow: 1;
	min-width: 10em;
	flex-basis: 40%;
}
smoothly-form[looks="grid"] > form > fieldset {
	border: rgba(var(--text-color), .5) solid .5px;
}
smoothly-form[looks="line"] > form > fieldset,
smoothly-form[looks="border"] > form > fieldset,
smoothly-form[looks="transparent"] > form > fieldset {
	gap: 2em
}

smoothly-form > form > div {
	display: flex;
	justify-content: end;
	gap: 1em;
	margin-top: 0.5rem;
}
